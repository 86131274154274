import React from 'react';
import ResourcesTab from '@fiverr-private/resources_tab';
import Nav from '../../lib/Nav';
import withContext from '../../lib/withContext';
import { BusinessProSolutionsNavigation } from '../../components/BusinessProSolutionsNavigation';
import { GuestBecomeSellerLink } from '../../components/GuestLinks/GuestBecomeSellerLink';
import JoinLink from '../../components/JoinLink';
import Layout from '../../components/Layout';
import LeanLocaleSettingsLink from '../../components/LocaleSettingsLinks/LeanLocaleSettingsLink';
import LoginLink from '../../components/LoginLink';
import { Logo } from '../../components/Logo';
import MainRow from '../../components/MainRow';
import Navicon from '../../components/Navicon';

import '../LoggedOutHomepage/style.scss';
import './style.scss';

export const LoggedOutHomepageGerman = () => (
    <Layout className="logged-out-homepage-header">
        <MainRow>
            <Navicon />
            <Logo />
            <Nav alignToRight className="nav-with-space">
                <Nav.Item className="nav-solution-button" displayFromMD>
                    <BusinessProSolutionsNavigation isTransparent={false} />
                </Nav.Item>
                <Nav.Item displayFromMD>
                    <ResourcesTab headerType="logged_out" />
                </Nav.Item>
                <Nav.Item displayFromLG>
                    <LeanLocaleSettingsLink />
                </Nav.Item>
                <Nav.Item displayFromMD>
                    <GuestBecomeSellerLink />
                </Nav.Item>
                <Nav.Item displayFromSM>
                    <LoginLink />
                </Nav.Item>
                <Nav.Item>
                    <JoinLink />
                </Nav.Item>
            </Nav>
        </MainRow>
    </Layout>
);

/**
 * @type {React.FC}
 */
export default withContext(LoggedOutHomepageGerman);
